import React from "react";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Alert,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

const ContactComponent = () => {
  const [showSuccessNotification, setShowSuccessNotification] = React.useState(false);
  const [showFailureNotification, setShowFailureNotification] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  //const onClick = () => sendForm();
  const handleSubmit = (event) =>{
    event.preventDefault();
    var form = document.getElementById("contact_Oxanium");

    //var status = document.getElementById("my-form-status");
    var data = new FormData(event.target);
    var formEmpty = false;
    for (const pair of data.entries()) {
      //console.log(pair[0], pair[1]);
      if(pair[1] === ""){
        formEmpty = true;
        break;
      }
    }
    if(formEmpty){
      toggle(null)
    }else{
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
            'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          //status.innerHTML = "Thanks for your submission!";
          setShowFailureNotification(false);
          setShowSuccessNotification(true);
          form.reset()
        } else {
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              //status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
              setShowFailureNotification(true);
            } else {
              //status.innerHTML = "Oops! There was a problem submitting your form"
              setShowFailureNotification(true);
            }
          })
        }
      }).catch(error => {
        //status.innerHTML = "Oops! There was a problem submitting your form"
        setShowFailureNotification(true);
      });
    }
  }

  const toggle = () => {
    setModal(!modal);
  }

  const closeSuccessNotification = () =>{
    setShowSuccessNotification(false);
  }

  const closeFailureNotification = () =>{
    setShowFailureNotification(false);
  }

  const FormSuccessNotification = () => (
    <Container>
      <div className="spacer">
        <Row>
          <Col md="12">
            <Alert color="success">
              Votre message a été envoyé. Nous reviendrons vers vous rapidement.
              <button onClick={closeSuccessNotification} type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </Alert>
          </Col>
        </Row>
      </div>
    </Container>
  );

  const FormFailureNotification = () => (
    <Container>
      <div className="spacer">
        <Row>
          <Col md="12">
            <Alert color="danger">
              Un problème est survenu lors de l'envoi de votre message. Merci de nous contacter par mail sur : <a href="mailto:contact@oxanium.com">contact@oxanium.com</a>
              <button onClick={closeFailureNotification} type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </Alert>
          </Col>
        </Row>
      </div>
    </Container>
  );

  return (
    <div id="contact">
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Laissez nous votre message</h1>
              <h6 className="subtitle">
                Nous accordons une attention particulière à chacune de vos demandes et vous contacterons rapidement pour répondre à vos besoins.
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact1">
        { showSuccessNotification ? <FormSuccessNotification /> : null }
        { showFailureNotification ? <FormFailureNotification /> : null }
        
        <Modal size="sm" isOpen={modal} toggle={toggle.bind(null)}>
              <ModalHeader toggle={toggle.bind(null)}>Champs requis</ModalHeader>
              <ModalBody>
                Merci de remplir tous les champs du formulaire.
              </ModalBody>
              <ModalFooter className="justify-content-center">
                  <Button color="primary" onClick={toggle.bind(null)}>Annuler</Button>
              </ModalFooter>
          </Modal>
        <Container>
          <Row>
            <div className="spacer">
              <Row className="m-0">
                <Col lg="8">
                  <div className="contact-box p-r-40">
                    <h4 className="title">Nous Contacter</h4>
                    <Form 
                      id="contact_Oxanium" 
                      onSubmit={handleSubmit} 
                      action="https://formspree.io/f/manybjva" 
                      method="POST"
                      >  
                      <Row>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input type="text" placeholder="Nom" name="name" id="userName"/>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input type="text" placeholder="Email" name="email" id="userMail"/>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="m-t-15">
                            <Input
                              type="textarea"
                              name="message"
                              placeholder="Message"
                              id="userMessage"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <Button
                            type="submit"
                            className="btn btn-danger-gradiant m-t-20 btn-arrow"
                          >
                            <span>
                              {" "}
                              ENVOYER <i className="ti-arrow-right"></i>
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="detail-box p-40 bg-info">
                    <h2 className="text-white">OXANIUM</h2>
                    <p className="text-white m-t-30 op-8">
                      +33 6 58 18 93 30
                      <br />
                      +33 6 28 79 42 13
                      <br />
                      <br /> contact@oxanium.com
                    </p>
                    <br />
                    <p className="text-white op-8">
                      28 Rue Maurice Berteaux
                      <br /> 95870 Bezons - France
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactComponent;
