
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'reactstrap';

import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

const MentionsLegales = () => {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="static-slider-head">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="8" md="6" className="align-self-center text-center">
                                    <h1 className="title">Mentions légales</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="spacer bg-light">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">1. Éditeur du site</h3>
                                            <h4 className="subtitle font-light">Nom de l’entreprise : OXANIUM</h4>
                                            <h4 className="subtitle font-light">Statut juridique : SAS, société par actions simplifiée</h4>
                                            <h4 className="subtitle font-light">Siège social : 28 Rue Maurice Berteaux, 95870 Bezons</h4>
                                            <h4 className="subtitle font-light">Numéro SIRET : 938 426 285 00012</h4>
                                            <h4 className="subtitle font-light">Téléphone : +33 6 28 79 42 13</h4>
                                            <h4 className="subtitle font-light">Adresse email : contact@oxanium.com</h4>
                                            <h4 className="subtitle font-light">Directeur de publication : MESSAOUD Ismail</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">2. Hébergement</h3>
                                            <h4 className="subtitle font-light">Nom de l’hébergeur : Netlify, Inc</h4>
                                            <h4 className="subtitle font-light">Adresse de l’hébergeur : 512 2nd Street, Fl 2, San Francisco, CA 94107</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">3. Propriété intellectuelle</h3>
                                            <h4 className="subtitle font-light">Le contenu du site, incluant les textes, images, graphismes, logos, vidéos, et autres éléments, est protégé par les lois en vigueur sur la propriété intellectuelle. Toute reproduction, représentation, modification ou distribution, partielle ou intégrale, du contenu est interdite sans l'autorisation écrite préalable de OXANIUM.</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">4. Données personnelles</h3>
                                            <h4 className="subtitle font-light">Les informations recueillies sur ce site sont utilisées dans le cadre de recherche des missions ou des freelances. Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, et au RGPD, vous disposez d’un droit d’accès, de rectification, et de suppression de vos données personnelles. Pour exercer ce droit, contactez-nous à <a href="mailto:contact@oxanium.com">contact@oxanium.com</a>.</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">5. Responsabilité</h3>
                                            <h4 className="subtitle font-light">OXANIUM ne saurait être tenu responsable des dommages directs ou indirects résultant de l'utilisation du site ou de l'impossibilité d'y accéder.</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">6. Droit Applicable</h3>
                                            <h4 className="subtitle font-light">Tout litige lié à l’utilisation du site <a href="https://www.oxanium.com">https://www.oxanium.com</a> est soumis au droit français et relève de la compétence exclusive des tribunaux français.</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">7. Cookies</h3>
                                            <h4 className="subtitle font-light">Le site peut utiliser des cookies pour améliorer l'expérience utilisateur. Vous pouvez gérer vos préférences dans les paramètres de votre navigateur.</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h3 className="title font-weight-bold">8. Lexique</h3>
                                            <h4 className="subtitle font-light">Les termes utilisés dans les mentions légales sont définis conformément à la loi n° 78-17 du 6 janvier 1978.</h4>                 
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

MentionsLegales.propTypes = {
    classes: PropTypes.object
};

export default MentionsLegales;
